import { useCallback, useEffect, useRef, useState } from 'react';
import "./../css/DDPage.scss";
import DDHead from './DD/DDHead';
import DDBody from './DD/DDBody';
import NavBar from './NavBar';
import { useNavigate } from 'react-router-dom';
import AnimaBlacker from './AnimaBlacker';
function DDPage(props) {
    const [headData, setHeadData] = useState({
        skill_text: "",
        skillset: []
    });
    // React utils
    const [components, setComponents] = useState([]);
    const [trackers, setTrackers] = useState([]);
    const blackerRef = useRef(null);
    const bodyRef = useRef(null);
    const [navClass, setNavClass] = useState('');
    const [navSticky, setNavSticky] = useState(false);
    const navigate = useNavigate();
    const [currentY, setCurrentY] = useState(0);

    const fetchComponentData = useCallback(async () => {
        // Get current timestamp
        const timestamp = Date.now();
        const data = await fetch('data/page/' + props.page + '.json?ver=' + timestamp);
        const json = await data.json();
        
        document.title = json.tabTitle;
        setHeadData(json.head);
        setComponents(json.components);
        setTrackers(json.trackers);
        
        console.log("Components loaded");
    }, [props.page]);

    useEffect(() => {
        console.log("Fetching component data of " + props.page + "...");
        fetchComponentData();
    }, [props.page, fetchComponentData]);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const backToLandingPage = useCallback(() => {
        blackerRef.current.flashBlackerThen(() => {
            navigate('/');
        }, bodyRef);
    }, [blackerRef, bodyRef, navigate]);

    const goBackToDesign = useCallback(() => {
        blackerRef.current.flashBlackerThen(() => {
            navigate('/');
        }, bodyRef);
    }, [blackerRef, bodyRef, navigate]);

    const goBackToEssay = useCallback(() => {
        blackerRef.current.flashBlackerThen(() => {
            navigate('/essay');
        }, bodyRef);
    }, [blackerRef, bodyRef, navigate]);

    // Hide navigation bar when the scroll have not passed the body
    useEffect(() => {
        if(!bodyRef.current) return;
        const bodyPos = bodyRef.current.offsetTop;
        if (currentY > bodyPos) {
            setNavSticky(true);
        }
        else {
            setNavSticky(false);
        }
    }, [currentY, bodyRef]);

    const handleScroll = event => {
        setCurrentY(window.scrollY);
    };

    useEffect(() => {
        // Scroll event
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (    
        <div>
            <AnimaBlacker ref={blackerRef} />
            <NavBar 
                customClass={navClass} 
                isSticky={navSticky} 
                logoAction={backToLandingPage} 
                designAction={goBackToDesign} 
                essayAction={goBackToEssay} />
            <div>
                <DDHead
                    title={props.title}
                    image={props.image}
                    skillText={headData.skill_text}
                    skillset={headData.skillset}
                />
                <DDBody ref={bodyRef} components={components} trackers={trackers} setNavClass={setNavClass} />
            </div>
        </div>
    );
}

export default DDPage;
