import '../css/LDEssay.scss';
import { forwardRef, useEffect, useState } from 'react';
import config from './../config.json';
import EssayMenu from './EssayMenu';

const LDEssay = forwardRef((props, ref) => {
    const [essays, setEssays] = useState([]);

    const fetchData = async () => {
        // Send GET request
        const response = await fetch(config.API_URL + '/post.php?limit=6');
        // If response is 200 OK
        if (response.ok) {
            const json = await response.json();
            setEssays(json);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    return (
        <div ref={ref}>
            <EssayMenu title='Essays' essays={essays} />
        </div>
    );
});

export default LDEssay;