import '../../css/DD/DDChip.scss';

function DDChip(props) {
    const { data } = props;
    return (
        <div className={`Chip ${data.boxClass || ''}`}>
            <p className={`${data.textClass || ''}`} dangerouslySetInnerHTML={{ __html: data.text }}></p>
        </div>
    );
}

export default DDChip;
