import { useEffect } from 'react';
import '../../css/DD/DDImage.scss';
import { useRef } from 'react';

function DDImage(props) {
    const localRef = useRef(null);
    const imageRef = useRef(null);
    useEffect(() => {
        if (imageRef.current.offsetWidth > localRef.current.offsetWidth) {
            localRef.current.style.width = localRef.current.offsetLeft + localRef.current.offsetWidth + "px";
        }
    }, []);
    return (
        <div ref={localRef} className={`DDImage ${props.styBox ?? ''}`} style={{
            width: props.boxWidth,
            height: props.boxHeight
        }}>
            <img ref={imageRef} className={`Image ${props.sty ?? ''}`} src={props.url} alt={props.alt} style={{
                width: props.width,
                height: props.height
            }} />
            {props.alt &&
            <p className="Body-3 Black-2" dangerouslySetInnerHTML={{ __html: props.alt }}></p>
            }
        </div>
    );
}

export default DDImage;