import config from '../config.json';
import getToken from './getToken';

async function fetchUploadedFiles() {
    const response = await fetch(config.API_URL + '/get_uploaded_files.php', {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + getToken()
        }
    });
    if (response.ok) {
        const json = await response.json();
        return json;
    }
    else {
        throw new Error('Failed to fetch uploaded files');
    }
}

export default fetchUploadedFiles;