import MarkdownView from 'react-showdown';
import '../css/Essay.scss';
import EssayQuote from './EssayQuote';
import EssayImage from './EssayImage';
import getReadDuration from '../utility/getReadDuration';
import { useEffect, useRef } from 'react';

function Essay(props) {
    const { title, label, content, footer } = props;

    // React utils
    const contentRef = useRef(null);

    // Animation callback
    useEffect(() => {
        // Set opacity to 1
        contentRef.current.style.opacity = 1;
    }, [contentRef])

    return (
        <div ref={contentRef} className='Essay-View'>
            <div className='Padding-Bottom-M Flex F-Col Gap-M'>
                <p className='Body-3 Black-2'>{getReadDuration(content)} min read</p>
                <div>
                    <p className='Title-3 Black-2'>{label}</p>
                    <h1 className='Title-1 Black-1'>{title}</h1>
                </div>
            </div>
            <MarkdownView markdown={content} components={{ EssayQuote, EssayImage }} className='Content-Essay' />
            <MarkdownView markdown={footer} className='Footer-Note' />
        </div>
    );
}

export default Essay;