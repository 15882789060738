import DDTimelineLeft from "./DDTimelineLeft";
import '../../css/DD/DDTimeline.scss';
import DDAuuu from "./DDAuuu";

function DDTimeline(props) {
    const { data } = props;
    return (
        <div className="Timeline">
            <DDTimelineLeft data={data.left} />
            <DDTimelineRight data={data.right} />
        </div>
    );
}

export default DDTimeline;

function DDTimelineRight(props) {
    const { data } = props;
    return (
        <div className="Right">
            <DDAuuu data={data} />
        </div>
    );
}