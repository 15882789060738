import { useEffect, useRef, useState } from "react";
import config from "../config.json";
import fetchUploadedFiles from "../utility/fetchUploadedFiles";
import AdmSidebar from "./AdmSidebar";
import getToken from "../utility/getToken";

function AdmPageFileManager(props) {
    const [files, setFiles] = useState([]);
    const [fileUploadMessage, setFileUploadMessage] = useState('');
    const fileUploadRef = useRef(null);
    const [deletingFileName, setDeletingFileName] = useState('');

    const fetchFiles = async () => {
        const uploadedFiles = await fetchUploadedFiles();
        const files = uploadedFiles.map((f, i) => {
            return {
                name: f,
                url: f
            }
        })
        setFiles(files);
    };

    const uploadFile = async () => {
        setFileUploadMessage('Uploading file, please wait...');

        const formData = new FormData();
        formData.append('file', fileUploadRef.current.files[0]);

        const response = await fetch(config.API_URL + '/upload_file.php', {
            method: 'POST',
            headers: {
                'Authorization': getToken()
            },
            body: formData
        });

        if (response.ok) {
            setFileUploadMessage('File has been uploaded successfully');

            fetchFiles();
        } else {
            const json = await response.json();
            setFileUploadMessage(json.error ?? 'Failed to upload file, please contact administrator')
        }
    };

    const deleteFile = async (fileName) => {
        setDeletingFileName(fileName);

        const response = await fetch(config.API_URL + '/delete_file.php', {
            method: 'POST',
            headers: {
                'Authorization': getToken(),
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                file_name: fileName
            })
        });

        if (response.ok) {
            alert('File has been deleted successfully');

            setDeletingFileName('');

            fetchFiles();
        } else {
            const json = await response.json();
            alert(json.error ?? 'Failed to delete the file, please contact administrator')
            setDeletingFileName(''); 
        }  
    };

    useEffect(() => {
        // Fetch uploaded files
        fetchFiles();
    }, []);

    useEffect(() => {
        // Set title
        document.title = 'Admin Panel - File Manager';
    }, []);

    return (
        <div className='Flex'>
            <AdmSidebar />
            <div className='Padding-XL Flex F-Col Gap-M' style={{ width: '100%' }}>
                <p className='Title-3'>File Manager</p>
                <p className='Body-3'>All file that you upload here will be publicly visible on {config.API_URL}/upload!</p>
                <div className='Flex F-Col Gap-M Bg-Black-3 Padding-M'>
                    <p className='Body-2'>Upload a new file</p>
                    {fileUploadMessage && <p className='Body-3'>{fileUploadMessage}</p>}
                    <div className='Flex Gap-M'>
                        <input ref={fileUploadRef} type='file' className='Body-3' />
                        <button onClick={uploadFile} className='Body-3'>Upload</button>
                    </div>
                </div>
                <table className='File-Table'>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>URL</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {files.map((f) => {
                            return (
                                <tr key={`file-${f.name}`}>
                                    <td>{f.name}</td>
                                    <td>{`${config.API_URL}/upload/${f.name}`}</td>
                                    <td>
                                        <button onClick={() => deleteFile(f.name)} disabled={deletingFileName !== ''}>{deletingFileName === f.name ? 'Deleting...' : 'Delete'}</button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default AdmPageFileManager;