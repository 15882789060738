import { useEffect, useRef } from "react";

function AnimaWhiter(props) {
    // React utils
    const ref = useRef(null);

    // Constants
    const TRANSITION_DELTA = 10;
    const WHITER_OPACITY_TRANSITION_TIME = 500;

    // Init callback
    useEffect(() => {
        if (!ref) return;

        // Disable body scroll x
        document.body.style.overflowX = 'hidden';

        const bodyHeight = document.body.offsetHeight;
        ref.current.style.width = window.innerWidth + "px";
        ref.current.style.height = bodyHeight + "px";

        // Set opacity of content
        ref.current.style.opacity = 0;

        setTimeout(() => {
            // Hide whiter
            ref.current.style.display = 'none';

            // Enable body scroll x
            document.body.style.overflowX = 'auto';
        }, TRANSITION_DELTA + WHITER_OPACITY_TRANSITION_TIME);
    }, []);

    return (
        <div ref={ref} className='DDWhiter'></div>
    );
}

export default AnimaWhiter;