import "./../../css/DD/DDHead.scss";
import isVideo from '../../utility/isVideo';
function DDHead(props) {
    return (
        <div className="DDHead">
            <div className="DDHead-Container">
                <div className="Head">
                    <h1 className="Title">{props.title}</h1>
                    <HeadImage src={props.image} title={props.title} />
                    <div className="Skill-Section">
                        <p className="Skill-Info">{props.skillText}</p>
                        <div className="Skill">
                            {props.skillset.map((s, i) => (
                                <p key={`skill-${i}`}>{s}</p>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DDHead;

function HeadImage(props) {
    const { src, title } = props;
    return !isVideo(src) ? (
            <div className="Image">
                <img src={src} alt={title} />
            </div>
        ) : (
            <div className="Video">
                <video
                    autoPlay
                    muted
                    playsInline
                    loop
                >
                    <source src={src} />
                </video>
            </div>
        )
    ;
}