import { useEffect, useRef, useState } from "react";
import getToken from "../utility/getToken";
import config from "../config.json";
import AdmSidebar from "./AdmSidebar";

function AdmPageChangePassword(props) {

    const [message, setMessage] = useState('');
    const oldPassRef = useRef(null);
    const newPassRef = useRef(null);
    const confirmPassRef = useRef(null);

    const processChangePassword = async () => {
        setMessage('Changing password, please wait...');

        const oldPass = oldPassRef.current.value;
        const newPass = newPassRef.current.value;
        const confirmPass = confirmPassRef.current.value;

        if (newPass !== confirmPass) {
            setMessage('Passwords do not match');
            return;
        }

        const response = await fetch(config.API_URL + '/change_password.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                old_password: oldPass,
                new_password: newPass
            })
        });

        if (response.ok) {
            setMessage('Password has been changed successfully');
        } else {
            const json = await response.json();
            setMessage(json.error ?? 'Failed to change password, API is unreachable');
        }
    };

    useEffect(() => {
        // Set title
        document.title = 'Admin Panel - Change Password';
    }, []);

    return (
        <div className='Flex'>
            <AdmSidebar />
            <div className='Padding-XL Flex F-Col Gap-L' style={{ width: '100%' }}>
                <p className='Title-3'>Change Password</p>
                {message && <p className='Body-3 Bg-Green-1 Padding-S'>{message}</p>}
                <div className='Flex F-Col Gap-S'>
                    <input className='Padding-S Body-3' placeholder='Old Password' ref={oldPassRef} type='password' />
                    <input className='Padding-S Body-3' placeholder='New Password' ref={newPassRef} type='password' />
                    <input className='Padding-S Body-3' placeholder='Confirm New Password' ref={confirmPassRef} type='password' />
                    <button className='Button Padding-S Body-3 White-1 Bg-Black-2 Bg-Black-1-Hover' onClick={processChangePassword}>Submit</button>
                </div>
            </div>
        </div>
    );
}

export default AdmPageChangePassword;