import { forwardRef, useCallback, useEffect, useState } from "react";
import '../../css/DD/DDSidebar.scss';

const DDSidebar = forwardRef((props, ref) => {
    const [activePageTracker, setActivePageTracker] = useState("tracker-1");
    const [readProgress, setReadProgress] = useState(0);
    const [pageTrackersMap, setPageTrackersMap] = useState({});
    const [pageTrackersLinear, setPageTrackersLinear] = useState([]);

    const collectTrackerPosition = useCallback((data, dataLinear, trackerList) => {
        trackerList.forEach((t, i) => {
            if (t.type === "tracker") {
                const element = document.getElementById(t.id);
                if (element) {
                    const posY = element.getBoundingClientRect().top;
                    data[t.id] = {
                        number: dataLinear.length,
                        posY: posY
                    };
                    dataLinear.push({
                        id: t.id,
                        posY: posY
                    });
                }
                else {
                    console.error("Tracker ID " + t.id + " is not rendered.");
                }
            }
            else if (t.type === "tracker-list") {
                collectTrackerPosition(data, dataLinear, t.tracker);
            }
        });
    }, []);

    const fetchPageTrackers = useCallback(() => {
        let trackerPosition = {};
        let trackerPositionLinear = [];
        collectTrackerPosition(trackerPosition, trackerPositionLinear, props.trackers);
        console.log(trackerPosition);
        setPageTrackersMap(trackerPosition);
        setPageTrackersLinear(trackerPositionLinear);
        setReadProgress(Math.ceil(100.0 / trackerPositionLinear.length));
    }, [props.trackers, collectTrackerPosition]);

    const moveToPageTracker = useCallback((i) => {
        if (pageTrackersMap[i]) {
            setActivePageTracker(i);
    
            // Scroll view to page tracker i-th
            const posY = pageTrackersMap[i].posY - ref.current.offsetHeight + 1;

            window.scrollTo({
                top: posY,
                behavior: 'smooth'
            });
        } else {
            console.error(`Page tracker with id ${i} not found.`);
        }
    }, [pageTrackersMap, ref]);

    const updateReadingProgress = useCallback((i) => {
        console.log("Reading progress updated to ID " + i);
        setReadProgress(Math.ceil((i + 1) * 100 / pageTrackersLinear.length));
    }, [pageTrackersLinear]);

    const [currentY, setCurrentY] = useState(0);

    const handleScroll = event => {
        setCurrentY(window.scrollY);
    };

    useEffect(() => {
        window.removeEventListener('scroll', handleScroll);
        window.addEventListener('scroll', handleScroll);
        console.log('Scroll listener set up');
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    useEffect(() => {
        console.log("Fetching page tracker...");
        
        fetchPageTrackers();
        console.log(props.components);
    }, [props.components, fetchPageTrackers]);

    useEffect(() => {
        if (pageTrackersMap[activePageTracker]) {
            let currentActivePageTracker = pageTrackersMap[activePageTracker].number;
            let accuY = currentY + ref.current.offsetHeight;
            // Scroll DOWN
            while (currentActivePageTracker + 1 < pageTrackersLinear.length && accuY > pageTrackersLinear[currentActivePageTracker].posY) {
                currentActivePageTracker++;
            }
            // Scroll UP
            while (currentActivePageTracker - 1 >= 0 && accuY < pageTrackersLinear[currentActivePageTracker].posY) {
                currentActivePageTracker--;
            }
            if (currentActivePageTracker !== pageTrackersMap[activePageTracker].number) {
                setActivePageTracker(pageTrackersLinear[currentActivePageTracker].id);
                updateReadingProgress(currentActivePageTracker);
                console.log("Active page tracker changed from " + activePageTracker + " to " + pageTrackersLinear[currentActivePageTracker].id);
            }
        }
    }, [currentY, activePageTracker, pageTrackersMap, pageTrackersLinear, updateReadingProgress, ref]);

    useEffect(() => {
        props.setSidebarHeight(ref.current.offsetHeight);
    }, [ref, props]);
    
    return (
        <div ref={ref} className="Sidebar">
            <div className="Extra">
                <div className="Page-Tracker">
                    {props.trackers && props.trackers.map((t, i) => (
                        t.type === "tracker" ? (
                            <div className={`Item ${t.id === activePageTracker ? 'Active' : ''}`} onClick={() => moveToPageTracker(t.id)} key={t.id}>
                                <p>{t.name}</p>
                            </div>
                        ) : (
                            <div key={t.id} className="Page-Tracker">
                                {t.tracker.map((t2, j) => (
                                    <div className={`Item ${t2.id === activePageTracker ? 'Active' : ''}`} onClick={() => moveToPageTracker(t2.id)} key={t2.id}>
                                        <p>{t2.name}</p>
                                    </div>
                                ))}
                            </div>
                        )
                    ))}
                </div>
                <div className="Slider" style={{height: `${32*pageTrackersLinear.length + 12*(pageTrackersLinear.length-1)}px`}}>
                    <div className="Active" style={{height: `${readProgress}%`}}></div>
                </div>
            </div>
            <div className="Progress">
                <p>{readProgress}%</p>
            </div>
        </div>
    );
});

export default DDSidebar;