function getParagraphOnlyFromMarkdown(markdownString) {
    const paragraphs = markdownString.trim().split(/\n|\r\n/); // Split by any combination of newlines
    const filteredParagraphs = paragraphs.filter(paragraph => {
        // Remove leading/trailing whitespace and check for non-empty content
        const trimmedParagraph = paragraph.trim();
        return trimmedParagraph.length > 0 && 
        !trimmedParagraph.startsWith('<') && 
        !trimmedParagraph.startsWith('#') &&
        !trimmedParagraph.match(/^\d+\.\s/) && // Regex for numbered lists
        !trimmedParagraph.match(/^\*|-\s/) && // Regex for bullet points
        !trimmedParagraph.match(/^```/); // Exclude code blocks
    });
    return filteredParagraphs.join(' ');
}

export default getParagraphOnlyFromMarkdown;