import { Navigate } from "react-router-dom";
import getToken from "./utility/getToken";
import config from "./config.json";
import removeToken from "./utility/removeToken";

export const ProtectedRoute = ({ children }) => {
    if (!getToken()) {
        // User is not authenticated
        return <Navigate to="/waking-up" />;
    }
    const checkToken = async () => {
        const response = await fetch(`${config.API_URL}/validate_token.php`, {
            method: 'POST',
            headers: {
                Authorization: 'Bearer ' + getToken()
            }            
        });

        if (!response.ok) {
            removeToken();
            return <Navigate to="/waking-up" />;
        }
    }

    checkToken();

    return children;
};