import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../config.json";
import AdmSidebar from "./AdmSidebar";
import getParagraphOnlyFromMarkdown from "../utility/getParagraphOnlyFromMarkdown";

function AdminPageEssays(props) {
    const navigate = useNavigate();
    
    const [essays, setEssays] = useState([]);
    const [isEssayLoaded, setIsEssayLoaded] = useState(false);

    const fetchData = async () => {
        const response = await fetch(`${config.API_URL}/post.php`);
        if (response.ok) {
            const json = await response.json();
            setEssays(json);
            setIsEssayLoaded(true);
        } else {
            alert('Failed to fetch essays list');
        }
    };

    useEffect(() => {
        // Set title
        document.title = 'Admin Panel - Manage Essay';
        // Fetch essay data
        fetchData();
    }, []);

    return (
        <div className='Flex'>
            <AdmSidebar />
            <div className='Padding-XL Flex F-Col Gap-M' style={{width: '100%'}}>
                <p className='Title-3'>Manage Essays</p>
                <button className='Button Padding-S Bg-Black-2 Bg-Black-1-Hover White-1' onClick={() => navigate('/admin/add_essay')} style={{width: '200px'}}>Create New Essay</button>

                {isEssayLoaded ? (
                    <table className='Essay-Table Body-3 Black-1'>
                        <thead>
                            <tr>
                                <th>No.</th>
                                <th>Title</th>
                                <th>Content</th>
                                <th>Published At</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {essays.map((e, i) => {
                                return (
                                    <tr key={`essay-${e.path}`}>
                                        <td>{i+1}</td>
                                        <td>{e.title}</td>
                                        <td>{getParagraphOnlyFromMarkdown(e.content)}</td>
                                        <td>{e.created_at}</td>
                                        <td>
                                            <button onClick={() => navigate(`/admin/essay/edit/${e.path}`)}>Update</button>
                                            <button onClick={() => navigate(`/admin/essay/delete/${e.path}`)}>Delete</button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                ) : (
                    <p className='Body-2 Black-1'>Loading essay, please wait...</p>
                )}
            </div>
        </div>
    );
}

export default AdminPageEssays;