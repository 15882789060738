import arrow from './../images/arrow.svg';
import './../App.scss';
import './../css/LDAbout.scss';

function LDAbout(props) {
  return (
      <div className="LD-About">
        <p className="Title">About Me</p>
        <p className="Text1" dangerouslySetInnerHTML={{ __html: props.data.text1 }}></p>
        <p className="Text2" dangerouslySetInnerHTML={{ __html: props.data.text2 }}></p>
        <div className="Footer">
          <LinkSocialSection data={props.data.social} />
          <LinkOtherSection data={props.data.other} />
        </div>
      </div>
  );
}

export default LDAbout;

function LinkSocialSection(props) {
  return (
    <div className="Social-Section">
      <p className="Title">Find me on socials</p>
      <div className="Link">
        {props.data.map((item, i) => (
          <LinkSocialItem key={`social-${i}`} href={item.value} text={item.name} />
        ))}
      </div>
    </div>
  );
}

function LinkSocialItem(props) {
  return (
    <a href={props.href}>
      <p>{props.text}</p>
      <img src={arrow} alt="Link Icon" className='FW-600' />
    </a>
  );
}


function LinkOtherSection(props) {
  return (
    <div className="Other-Section">
      <p className="Title">Other resource</p>
      <div className="Link">
        {props.data.map((item, i) => (
          <LinkOtherItem key={`other-${i}`} href={item.value} text={item.name} />
        ))}
      </div>
    </div>
  );
}

function LinkOtherItem(props) {
  return (
    <a href={props.href}>
      <p className="Black-1 Body-3">{props.text}</p>
      <img src={arrow} alt="Link Icon" />
    </a>
  );
}
