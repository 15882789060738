import "./../css/LDPage.scss";
import LDHead from './LDHead';
import LDWork from './LDWork';
import LDAbout from './LDAbout';
import { useEffect, useRef } from "react";
import LDProjectCards from "./LDProjectCards";
import NavBar from "./NavBar";
import { useNavigate } from "react-router-dom";
import AnimaWhiter from "./AnimaWhiter";

function LDPage(props) {
    // React utils
    const workRef = useRef(null);
    const contentRef = useRef(null);
    const navigate = useNavigate();

    // Init callback
    useEffect(() => {
        document.title = 'Faw, Designer';
    }, []);

    // Animation callback
    useEffect(() => {
        // Set opacity of content
        contentRef.current.style.opacity = 1;
    }, [contentRef])

    return (
        <div>
            <AnimaWhiter />
            <NavBar 
                customClass='Nv-Bg-White-2 Nv-Sticky'
                active='design'
                isSticky={true}
                essayAction={() => navigate('/essay')} />
            <div ref={contentRef} className="LD-App">
                <LDHead data={props.summary} />
                <div ref={workRef} hidden></div>
                <LDWork ref={workRef} data={props.work} />
                <LDProjectCards />
                <LDAbout data={props.about} />
            </div>
        </div>
    );
}

export default LDPage;
