import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";

const AnimaBlacker = forwardRef((props, ref) => {
    // React utils
    const localRef = useRef(null);

    // Constants
    const TRANSITION_DELTA = 10;
    const BLACKER_OPACITY_TRANSITION_TIME = 500;
    const BLACKER_BG_COLOR_TRANSITION_TIME = 350;

    useImperativeHandle(ref, () => ({
        flashBlackerThen(callback, bodyRef) {
            // Set localRef height matching bodyRef
            localRef.current.style.height = bodyRef.current.offsetHeight + "px";
            localRef.current.style.display = 'block';
            setTimeout(() => {
                localRef.current.style.opacity = 1;
                setTimeout(() => {
                    localRef.current.style.backgroundColor = '#fff';
                    setTimeout(() => {
                        callback();
                    }, BLACKER_BG_COLOR_TRANSITION_TIME);
                }, BLACKER_OPACITY_TRANSITION_TIME);
            }, TRANSITION_DELTA);
        }
    }));

    // Functions

    // Init callback
    useEffect(() => {
        if (!localRef) return;

        const bodyHeight = document.body.offsetHeight;

        localRef.current.style.width = '100%';
        localRef.current.style.height = bodyHeight + "px";

        // Set opacity (animation transition)
        localRef.current.style.opacity = 0;

        setTimeout(() => {
            // Hide blacker
            localRef.current.style.display = 'none';
        }, TRANSITION_DELTA + BLACKER_OPACITY_TRANSITION_TIME);
    }, []);

    return (
        <div ref={localRef} className='DDBlacker'></div>
    );
});

export default AnimaBlacker;