import { useEffect, useRef } from 'react';
import '../css/EssayImage.scss';
function EssayImage(props) {
    const { url, desc } = props;
    const imageRef = useRef(null);

    useEffect(() => {
        // Make the image ratio to 5:2
        console.log('Adjusting image...');
        imageRef.current.style.height = imageRef.current.offsetWidth / 5 * 2 + 'px';
    }, [imageRef])

    return (
        <div className='Essay-Image'>
            <div className='Image-Section'>
                <img ref={imageRef} src={url} alt={desc} />
            </div>
            <p className='Image-Desc'>{desc}</p>
        </div>
    );
}

export default EssayImage;