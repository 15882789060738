import Essay from "./Essay";

function AdmEssayForm(props) {
    const { title, label, content, footer, setTitle, setLabel, setContent, setFooter, onSave, saveText } = props;

    return (
        <div className='Flex F-Col Gap-M'>
            <div className='Flex Gap-M'>
                <div className='Flex F-Col Gap-S' style={{width: '50%'}}>
                    <p className='Body-2 Green-1'>Markdown</p>
                    <hr />
                    <div className='Flex F-Col Gap-S'>
                        <label className='Body-3 Black-1'>Title</label>
                        <input className='Body-3' defaultValue={title} type='text' onChange={(e) => setTitle(e.target.value)} />
                    </div>
                    <div className='Flex F-Col Gap-S'>
                        <label className='Body-3 Black-1'>Label</label>
                        <input className='Body-3' defaultValue={label} type='text' onChange={(e) => setLabel(e.target.value)} />
                    </div>
                    <div className='Flex F-Col Gap-S'>
                        <label className='Body-3 Black-1'>Content (Markdown)</label>
                        <textarea className='Body-3' defaultValue={content} rows={30} style={{width: '100%'}} onChange={(e) => setContent(e.target.value)}></textarea>
                    </div>
                    <div className='Flex F-Col Gap-S'>
                        <label className='Body-3 Black-1'>Footer</label>
                        <textarea className='Body-3' defaultValue={footer} rows={5} style={{width: '100%'}} onChange={(e) => setFooter(e.target.value)}></textarea>
                    </div>
                </div>
                <div className='Flex F-Col Gap-S' style={{width: '50%'}}>
                    <p className='Body-2 Green-1'>Preview</p>
                    <hr />
                    <Essay title={title} label={label} content={content} footer={footer} />
                </div>
            </div>
            <button className='Button Padding-S Bg-Black-2 Bg-Black-1-Hover Body-3 White-1' onClick={() => onSave()}>{saveText}</button>
        </div>
    );
}

export default AdmEssayForm;