import '../../css/DD/DDSolutionSection.scss';
import DDAuuu from './DDAuuu';

function DDSolutionSection(props) {
    const { data } = props;
    return (
        <div className={`Solution-Section-Border ${data.class ?? ''}`}>
            <DDAuuu data={{
                class: 'Solution-Section',
                space: data.space,
                component: data.component
            }} />
        </div>  
    );
}

export default DDSolutionSection;