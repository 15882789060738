import '../../css/DD/DDEmbed.scss';

function DDEmbed(props) {
    const { data } = props;
    return (
        <video 
            autoPlay muted loop
            name={data.title}
            style={{
                width: '100%',
                height: data.height ?? 'auto',
            }}>
            <source src={data.src}></source>
        </video>
    );
}

export default DDEmbed;