import "./../css/LDHead.scss";

function LDHead(props) {
    return (
        <div className="LD-Head">
            <p className="Text-1" dangerouslySetInnerHTML={{ __html: props.data.text }}></p>
            <p className="Text-2" dangerouslySetInnerHTML={{ __html: props.data.text2 }}></p>
        </div>
    );
}

export default LDHead;
