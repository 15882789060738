import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import '../css/AdmPage.scss';
import config from "../config.json";
import getToken from "../utility/getToken";
import AdmEssayForm from "./AdmEssayForm";
import essayDefaultExample from "../utility/essayDefaultExample";

function AdmPageCreateEssay(props) {
    const { essayId } = useParams();

    const navigate = useNavigate();
    const [title, setTitle] = useState('Write your title here');
    const [label, setLabel] = useState('Label');
    const [content, setContent] = useState(essayDefaultExample);
    const [footer, setFooter] = useState('1. Footer note 1\n2. Footer note 2');

    const doCreate = async () => {
        const response = await fetch(`${config.API_URL}/post.php`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + getToken()
            },
            body: JSON.stringify({
                path: essayId,
                title: title,
                content: content,
                footer: footer,
                label: label
            })
        });

        if (response.ok) {
            alert('Essay has been published successfully');
            navigate('/admin/essays');
        }
        else {
            alert('Failed to publish essay');
        }
    };

    useEffect(() => {
        // Set title
        document.title = 'Admin Panel - Create Essay';
    }, []);

    return (
        <div className='Flex F-Col Gap-M Padding-XL'>
            <button style={{width: '100px'}} className='Button Padding-S Bg-Black-2 Bg-Black-1-Hover Body-3 White-1' onClick={() => navigate('/admin/essays')}>Back</button>
            <p className='Title-3'>Create Essay</p>
            <AdmEssayForm title={title} label={label} content={content} footer={footer} setTitle={setTitle} setLabel={setLabel} setContent={setContent} setFooter={setFooter} onSave={doCreate} saveText='Publish' />
        </div>
    );
}

export default AdmPageCreateEssay;