import '../../css/DD/DDQuote.scss';
import DDAuuu from './DDAuuu';

function DDQuote(props) {
    const { data } = props;
    return (
        <div className="Quote-2">
            <img src={data.icon} alt="Icon" />
            <DDAuuu
                data={{
                    class: "Content",
                    component: data.component
                }}
            />
        </div>
    );
}

export default DDQuote;