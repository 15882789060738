import '../../css/DD/DDCard.scss';
import DDAuuu from './DDAuuu';

function DDCard(props) {
    const { data } = { ...props };
    data.class += " Card";
    return (
        <DDAuuu data={data} />
    );
}

export default DDCard;