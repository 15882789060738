function essayDefaultExample() {
    return `<EssayQuote text='This is quote. <span class="Black-2">—Tutorial</span>'>
This is paragraph. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas turpis lectus, luctus a velit non, posuere tincidunt est. Fusce at vulputate arcu, hendrerit bibendum odio. Aenean congue rhoncus turpis id ullamcorper.

<EssayImage url='https://placehold.co/500x200' desc='This is image description' />

## Heading 2

* List example 1
* List example 2
* List example 3

1. Number example 1
2. Number example 2
3. Number example 3`;
}

export default essayDefaultExample;