import '../../css/DD/DDList.scss';

function DDList(props) {
    const { data } = props;
    return (
        <div className="List-1">
            {data.item && data.item.map((d, k) => (
                <div className="Item" key={`${data.prefix ?? ''}-list-${k}`}>
                    <div className="Number-Section">
                        <div className="Number">
                            <p 
                                style={{
                                    lineHeight: '20px'
                                }}
                            >{k+1}</p>
                        </div>
                    </div>
                    <p 
                        className={data.textClass} 
                        dangerouslySetInnerHTML={{ __html: d }}
                    ></p>
                </div>
            ))}
        </div>
    );
}

export default DDList;