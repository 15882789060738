import '../css/LoginPage.scss';
import logo from '../images/logo.svg';
import config from '../config.json';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import setToken from '../utility/setToken';

function LoginPage(props) {
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const userRef = useRef(null);
    const passRef = useRef(null);

    const processLogin = async (event) => {
        event.preventDefault();
        
        setMessage('Logging in...');

        const username = userRef.current.value;
        const password = passRef.current.value;
        const response = await fetch(config.API_URL + '/login.php', {
            method: 'POST',
            headers: {
                'Authorization': 'Basic ' + btoa(`${username}:${password}`)
            }
        });

        if (response.ok) {
            const json = await response.json();
            // Save token
            setToken(json.token);
            setMessage('Login success, redirecting...');
            // Redirect to admin page
            setTimeout(() => {
                navigate('/admin');
            }, 1500);
        }
        else {
            const json = await response.json();
            setMessage(json.error ?? 'Failed to login, API is unreachable');
        }
    };

    // Init callback
    useEffect(() => {
        // Set title
        document.title = 'Admin Login';
    }, []);

    return (
        <div className='Login-Page'>
            <div className='Login-Box'>
                <h1 className='Title-2 Green-1'>Login</h1>
                {message && <p className='Body-2 White-1 Bg-Green-1 Padding-S'>{message}</p>}
                <form className='Flex F-Col'>
                    <input className='Padding-S Body-2' ref={userRef} type='text' placeholder='Username' autoFocus />
                    <input className='Padding-S Body-2' ref={passRef} type='password' placeholder='Password' />
                    <button type='submit' className='Button Padding-S Bg-Black-2 Body-2 Bg-Black-1-Hover White-1' onClick={processLogin}>Login</button>
                </form>
                <img src={logo} alt='Logo' className='Logo' />
            </div>
        </div>
    );
}

export default LoginPage;