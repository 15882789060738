import "./../../css/DD/DDBody.scss";
import DDSidebar from "./DDSidebar";
import { forwardRef, useEffect, useRef, useState } from "react";
import DDAuuu from "./DDAuuu";

const DDBody = forwardRef((props, ref) => {
    const [components, setComponents] = useState([]);
    const [trackers, setTrackers] = useState([]);
    const [sidebarHeight, setSidebarHeight] = useState(0);
    const firstContainerRef = useRef(null);
    const sidebarRef = useRef(null);

    // Update components from parent by adding page tracker
    useEffect(() => {
        setComponents(props.components);
    }, [props.components]);

    useEffect(() => {
        setTrackers(props.trackers);
    }, [props.trackers]);

    useEffect(() => {
        firstContainerRef.current.style.marginTop = -sidebarHeight + "px";
    }, [sidebarHeight]);

    return (
        <div ref={ref} className="DDBody">
            <DDSidebar
                ref={sidebarRef}
                trackers={trackers}
                setSidebarHeight={setSidebarHeight}
            ></DDSidebar>
            <div
                ref={firstContainerRef}
            >
                <DDAuuu 
                    data={components}
                />
            </div>
        </div>
    );
});

export default DDBody;
