import '../../css/DD/DDIconee.scss';

function DDIconee(props) {
    const { data } = props;
    return (
        <div className="DDIconee">
            {data.icon ? <img className="Icon" src={data.icon} alt="Icon" /> : null}
            <p className={data.class} dangerouslySetInnerHTML={{ __html: data.text }}></p>
        </div>
    );
}

export default DDIconee;