function getReadDuration(textToRead) {
    // If it is empty string
    if (textToRead.length === 0) return 0;

    // Make static variable
    const READ_SPEED = 200;

    // Count words in content and title
    const wordCount = textToRead.split(/\s+/).length;

    // Calculate approximate read time in minutes
    const readTime = Math.ceil(wordCount / READ_SPEED);

    return readTime;
}
export default getReadDuration;