import DDPage from './component/DDPage.js';
import LDPage from './component/LDPage.js';
import { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import LoginPage from './component/LoginPage.js';
import AdmPage from './component/AdmPage.js';
import AdmPageEditEssay from './component/AdmPageEditEssay.js';
import AdmPageFileManager from './component/AdmPageFileManager.js';
import AdmPageChangePassword from './component/AdmPageChangePassword.js';
import AdminPageEssays from './component/AdmPageEssays.js';
import AdmPageCreateEssay from './component/AdmPageCreateEssay.js';
import AdmPageDeleteEssay from './component/AdmPageDeleteEssay.js';
import LDEssayView from './component/LDEssayView.js';
import LDPageForEssay from './component/LDPageForEssay.js';
import { ProtectedRoute } from './ProtectedRoute.js';

function App() {
  const [ready, setReady] = useState(false);
  const [summary, setSummary] = useState({
      text: 'Loading...',
      text2: 'Loading...'
  });
  const [work, setWork] = useState([]);
  const [about, setAbout] = useState({
      text: 'Loading...',
      social: [],
      other: []
  });

  const fetchData = async () => {
      // Get current timestamp
      const timestamp = Date.now();
      const data = await fetch('/data/data.json?ver=' + timestamp);
      const json = await data.json();
      setSummary(json.summary);
      setWork(json.work);
      setAbout(json.about);

      console.log(json.work.length + " routes found");
      setReady(true);
  };

  useEffect(() => {
    console.log("Fetching meta data...");
    fetchData();
  }, []);

  return (!ready ? <p></p> : 
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={<LDPage summary={summary} work={work} about={about} />}
        ></Route>
        <Route
          path="/essay"
          element={<LDPageForEssay aboutData={about} />}
        ></Route>
        {work.map((w, i) => (
          <Route
            key={`route-${w.page}`}
            path={`/${w.page}`}
            element={<DDPage title={w.title} image={w.image_path} page={w.page} />}
          ></Route>
        ))}
        <Route
          path='/essay/:essayId'
          element={<LDEssayView />}
        ></Route>
        <Route
          path='/waking-up'
          element={<LoginPage />}
        ></Route>
        <Route
          path='/admin'
          element={
            <ProtectedRoute>
              <AdmPage />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path='/admin/essays'
          element={
            <ProtectedRoute>
              <AdminPageEssays />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path='/admin/add_essay'
          element={
            <ProtectedRoute>
              <AdmPageCreateEssay />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path='/admin/file_manager'
          element={
            <ProtectedRoute>
              <AdmPageFileManager />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path='/admin/change_password'
          element={
            <ProtectedRoute>
              <AdmPageChangePassword />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path='/admin/essay/edit/:essayId'
          element={
            <ProtectedRoute>
              <AdmPageEditEssay />
            </ProtectedRoute>
          }
        ></Route>
        <Route
          path='/admin/essay/delete/:essayId'
          element={
            <ProtectedRoute>
              <AdmPageDeleteEssay />
            </ProtectedRoute>
          }
        ></Route>
      </Routes>
    </BrowserRouter>
  )
}

export default App;
