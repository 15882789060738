import '../../css/DD/DDAuuu.scss';
import DDCard from './DDCard';
import DDCarousel from './DDCarousel';
import DDChip from './DDChip';
import DDEmbed from './DDEmbed';
import DDIconee from './DDIconee';
import DDImage from './DDImage';
import DDList from './DDList';
import DDQuote from './DDQuote';
import DDResultSection from './DDResultSection';
import DDSolutionSection from './DDSolutionSection';
import DDTimeline from './DDTimeline';
import { useCallback, useEffect, useRef, useState } from 'react';

function DDAuuu(props) {
    const { prefix, data } = props;
    const [isVisible, setIsVisible] = useState(false);
    const localRef = useRef(null);

    const handleScroll = useCallback(() => {
        if (localRef.current) {
            // Get ref position
            const bcrTop = localRef.current.getBoundingClientRect().top;
            const bcrBottom = localRef.current.getBoundingClientRect().bottom;
            const windowHeight = window.innerHeight;
            // Check if the top of the element is within the viewport
            if (bcrTop < windowHeight * 0.8) {
                setIsVisible(true);
            }
            // Navigation bar color change logic
            const DELTA_THRESHOLD = 50;
            if (data.navBarColor) {
                if ((-DELTA_THRESHOLD <= bcrTop && bcrTop <= 0) || (0 <= bcrBottom && bcrBottom <= DELTA_THRESHOLD)) {
                    const NAV_BAR_ELEMENT = document.querySelector('.Nav-Bar-Container');
                    // Remove all class match name Nv-Bg-* in the element
                    const classes = NAV_BAR_ELEMENT.classList;
                    for (let i = classes.length - 1; i >= 0; i--) { // Loop backwards to avoid issues with index changes
                        const className = classes[i];
                        if (className.includes('Nv-Bg-')) {
                            classes.remove(className);
                        }
                    }
                    // Add class
                    NAV_BAR_ELEMENT.classList.add(data.navBarColor);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        // Attach the scroll event listener
        window.addEventListener('scroll', handleScroll);
    
        // Clean up the event listener on component unmount
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return data ? (
        <div 
            ref={localRef} 
            className={`DDAuuu ${isVisible ? 'Show' : ''} ${data.class ?? ''} ${data.space ? `Space-${data.space}` : ''}`}
            style={data.customStyle ?? null}
            >
            {data.component && data.component.map((c, i) => {
                if (c.type === 'components') {
                    return <DDAuuu key={`auuu-${prefix}-${i}`} prefix={`${prefix}-${i}`} data={c.data} />;
                } else if (c.type === "solution_section") {
                    return <DDSolutionSection key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "grey_card") {
                    return <DDCard key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "carousel") {
                    return <DDCarousel key={`auuu-${prefix}-${i}`} data={c.data} parentRef={localRef} />
                } else if (c.type === "paragraph") {
                    return <p key={`auuu-${prefix}-${i}`} id={c.id ?? ''} className={c.class} dangerouslySetInnerHTML={{ __html: c.text }}></p>
                } else if (c.type === "image") {
                    return <DDImage key={`auuu-${prefix}-${i}`} sty={c.class} styBox={c.classBox} url={c.url} alt={c.alt} height={c.height} width={c.width} boxHeight={c.boxHeight} boxWidth={c.boxWidth} />
                } else if (c.type === "iconee") {
                    return <DDIconee key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "chip") {
                    return <DDChip key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "impact") {
                    return <DDResultSection key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "line") {
                    return <div key={`auuu-${prefix}-${i}`} className="Line" />
                } else if (c.type === "timeline") {
                    return <DDTimeline key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "video") {
                    return <DDEmbed key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "list") {
                    return <DDList key={`auuu-${prefix}-${i}`} data={c.data} />
                } else if (c.type === "quote") {
                    return (
                        <DDQuote key={`auuu-${prefix}-${i}`} data={c.data} />
                    );
                }
                return null;
            })}
        </div>
    ) : null;
};

export default DDAuuu;