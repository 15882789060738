import { useNavigate, useParams } from "react-router-dom";
import '../css/LDEssayView.scss';
import Essay from "./Essay";
import { useCallback, useEffect, useRef, useState } from "react";
import config from "../config.json";
import PrevAndNextEssay from "./PrevAndNextEssay";
import NavBar from "./NavBar";

function LDEssayView(props) {
    // React utils
    const { essayId } = useParams();
    const navigate = useNavigate();
    const [essayData, setEssayData] = useState(null);
    const contentRef = useRef(null);

    const fetchData = useCallback(async () => {
        const response = await fetch(`${config.API_URL}/get_post_by_path.php?p=${essayId}`);
        if (response.ok) {
            const json = await response.json();
            setEssayData({
                title: json.title,
                content: json.content,
                footer: json.footer,
                label: json.label
            });
        } else {
            alert('Failed to fetch essay data');
        }
    }, [essayId]);

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    const backToLandingPage = () => {
        navigate('/');
    };

    const goBackToDesign = () => {
        navigate('/');
    };

    const goBackToEssay = () => {
        navigate('/essay');
    };

    // Scroll to top smoothly if there is content change
    // Which means the user reads other essay
    useEffect(() => {
        // Scroll to top smootly
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, [essayData]);

    // Animation callback
    useEffect(() => {
        // Set opacity of content
        contentRef.current.style.opacity = 1;
    }, [contentRef])

    return (
        <div>
            <NavBar customClass='Nv-Bg-White-2' isSticky={true} logoAction={backToLandingPage} designAction={goBackToDesign} essayAction={goBackToEssay} />
            <div ref={contentRef} className='LD-App'>
                {essayData && (
                    <Essay title={essayData?.title} label={essayData?.label} content={essayData?.content} footer={essayData?.footer} />
                )}
                <PrevAndNextEssay essayId={essayId} />
            </div>
        </div>
    );
}

export default LDEssayView;